<template>
    <div>
        <div
            v-if="loading"
            class="container"
        >
            <loading-indicator
                v-if="loading"
                :title="$t('global.loadingMessage.loading')"
            />
        </div>
        <div v-else>
            <page-header
                :title="title"
                :show-back="true"
                @onClose="$router.go(-1)"
            />
            <div
                class="container"
                v-if="errorText"
            >
                <div
                    class="alert alert-warning text-start mt-2"
                    role="alert"
                >
                    <span v-html="errorText" />
                </div>
            </div>

            <div
                class="container"
                v-if="isCancelationSuccess"
            >
                <div
                    class="alert alert-success text-start mt-2"
                    role="alert"
                >
                    {{ $t('pages.balanceTransferById.confirmationMessage') }}
                </div>
            </div>

            <div v-if="balanceTransferData">
                <div class="container">
                    <balance-transfer-or-cash-out-info
                        :balance-transfer-or-cash-out-data="balanceTransferData"
                        :status="status"
                    />
                    <base-button
                        v-if="isCancelable"
                        :submitting="isCanceling"
                        :disabled="isCancelationSuccess || isCancelationFailure"
                        button-classes="btn btn-secondary"
                        @click="requestCancelation"
                    >
                        {{ $t('pages.balanceTransferById.cancelTransactionCta') }}
                    </base-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingIndicator from '@/components/LoadingIndicator'
    import PageHeader from '@/components/PageHeader'
    import { getBalanceTransferById, requestBalanceTransferCancelation } from '@/services/api'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import BaseButton from '@/components/base/BaseButton'
    import { i18n } from '@/utils/i18n'
    import { AciPaymentTransactionType, BalanceTransferStatus } from '@/store'
    import format from '@/mixins/format'
    import { delay } from '@/utils/async'
    import { navigation } from '@/mixins/navigation'
    import { DisplayableBalanceTransferOrCashOutStatus, ERROR_BALANCE_TRANSFER_NOT_CANCELABLE } from '@/data/constants'
    import BalanceTransferOrCashOutInfo from '@/components/BalanceTransferOrCashOutInfo'
    import dayjs from 'dayjs'

    export default {
        name: 'BalanceTransferById',
        components: {
            'balance-transfer-or-cash-out-info': BalanceTransferOrCashOutInfo,
            'loading-indicator': LoadingIndicator,
            'page-header': PageHeader,
            'base-button': BaseButton,
        },
        mixins: [format, navigation],
        props: {},
        data() {
            return {
                loading: true,
                errorText: null,
                balanceTransferData: null,
                isCanceling: false,
                isCancelationSuccess: false,
                isCancelationFailure: false,
            }
        },
        async mounted() {
            try {
                const response = await getBalanceTransferById(this.balanceTransferId)
                this.balanceTransferData = response.data.payload
            } catch (error) {
                this.errorText = i18n.t('global.errors.generic')
                ApiErrorHandler(error)
            } finally {
                this.loading = false
            }
        },
        computed: {
            balanceTransferId() {
                return Number(this.$route.params.id)
            },
            title() {
                return this.balanceTransferData?.lender
            },
            hasSettlementCutoffTimePassed() {
                // settlement date in pacific time
                const settlementDate = dayjs(this.balanceTransferData.settlementDate).tz('America/Los_Angeles')
                // settlement date
                const settlementDay = settlementDate.format('M/DD/YYYY')
                // settlement hour
                const settlementHour = settlementDate.get('hour')

                // get cutoff for the same settlement day at 5:00PM Pacific time
                let cutoffTime = dayjs.tz(`${settlementDay} 5:00 PM`, 'M/DD/YYYY h:mm A', 'America/Los_Angeles')

                // if the settlement was after the same day cutoff time then the balance transfer will be processed the next day
                if (settlementHour >= 17) {
                    cutoffTime = cutoffTime.add(1, 'day')
                }
                // check if current date is after the cutoff time
                return dayjs().isAfter(cutoffTime)
            },
            wasSentSuccessfullyToACIAndCoreCard() {
                // set of balance transfer state where the transaction should already be sent to ACI
                return [BalanceTransferStatus.Completed, BalanceTransferStatus.CoreCardSubmissionSuccess].includes(this.balanceTransferData.status)
            },
            isReversal() {
                return this.balanceTransferData?.transactionType === AciPaymentTransactionType.REVERSAL
            },
            isCanceled() {
                const canceledBeforeAciSubmission = this.balanceTransferData.status === BalanceTransferStatus.Canceled
                const canceledAfterAciSubmission = this.balanceTransferData.hasReversal
                return canceledBeforeAciSubmission || canceledAfterAciSubmission
            },
            status() {
                if (this.isReversal) {
                    return DisplayableBalanceTransferOrCashOutStatus.Reversal
                }
                if (this.isCanceled || this.isCancelationSuccess) {
                    return DisplayableBalanceTransferOrCashOutStatus.Canceled
                }
                if (this.wasSentSuccessfullyToACIAndCoreCard) {
                    return DisplayableBalanceTransferOrCashOutStatus.Completed
                }
                return DisplayableBalanceTransferOrCashOutStatus.Pending
            },
            isCancelable() {
                // it's cancelable if it wasn't canceled already and it wasn't sent to ACI yet and the settlement date has not passed
                return !this.isCanceled && !(this.wasSentSuccessfullyToACIAndCoreCard && this.hasSettlementCutoffTimePassed)
            },
        },
        methods: {
            async requestCancelation() {
                try {
                    this.isCanceling = true
                    // show at least 1s of loading state
                    const [response] = await Promise.all([await requestBalanceTransferCancelation(this.balanceTransferId), delay(1000)])
                    if (response.data.success) {
                        this.isCancelationSuccess = true
                        this.$logEvent('event_balance_transfer_cancelation_request', { balanceTransferId: this.balanceTransferData.id })
                    } else {
                        this.isCancelationFailure = true
                        if (response.data.error === ERROR_BALANCE_TRANSFER_NOT_CANCELABLE) {
                            this.errorText = i18n.t('pages.balanceTransferById.cancelatonRequestError')
                        } else {
                            this.errorText = i18n.t('global.errors.generic')
                        }
                    }
                } catch (error) {
                    this.errorText = ApiErrorHandler(error)
                } finally {
                    this.isCanceling = false
                }
            },
        },
    }
</script>
